import request from '@/utils/request'


// 查询人员测试列表
export function getHomeStatData(query) {
  return request({
    url: '/pcba/pcba/getHomeStatData',
    method: 'get',
    params: query
  })
}


