<template>
  <div class="content">
    <a-row :gutter="20">
      <a-col class="gutter-row" :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
        <div class="gutter-box">
          <div class="box-top">
            <!-- <a-icon type="smile" style="font-size: 38px; color: rgb(127, 98, 173)" /> -->
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="microchip"
              class="svg-inline--fa fa-microchip fa-fw"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
              style="color: rgb(127, 98, 173)"
              width="58px"
              height="58px"
            >
              <path
                fill="currentColor"
                d="M176 24c0-13.3-10.7-24-24-24s-24 10.7-24 24V64c-35.3 0-64 28.7-64 64H24c-13.3 0-24 10.7-24 24s10.7 24 24 24H64v56H24c-13.3 0-24 10.7-24 24s10.7 24 24 24H64v56H24c-13.3 0-24 10.7-24 24s10.7 24 24 24H64c0 35.3 28.7 64 64 64v40c0 13.3 10.7 24 24 24s24-10.7 24-24V448h56v40c0 13.3 10.7 24 24 24s24-10.7 24-24V448h56v40c0 13.3 10.7 24 24 24s24-10.7 24-24V448c35.3 0 64-28.7 64-64h40c13.3 0 24-10.7 24-24s-10.7-24-24-24H448V280h40c13.3 0 24-10.7 24-24s-10.7-24-24-24H448V176h40c13.3 0 24-10.7 24-24s-10.7-24-24-24H448c0-35.3-28.7-64-64-64V24c0-13.3-10.7-24-24-24s-24 10.7-24 24V64H280V24c0-13.3-10.7-24-24-24s-24 10.7-24 24V64H176V24zM160 128H352c17.7 0 32 14.3 32 32V352c0 17.7-14.3 32-32 32H160c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32zm192 32H160V352H352V160z"
              ></path>
            </svg>
            <div class="box-top-right">
              <span class="number">{{ homeStatData.pcbaNum }}</span>
              <span class="title">{{ $t('首页.pcba当日测试结果') }}</span>
            </div>
          </div>
          <a-divider style="margin:3px 0px"/>
          <div style="text-align: right;padding:10px 0px">
            <a-button class="btn" @click="gotoRouterPcba">
              {{ $t('首页.PCBA') }}
              <a-icon type="right" style="width: 26px; height: 12px; color: #fff" />
            </a-button>
          </div>
        </div>
      </a-col>
      <a-col class="gutter-row" :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
        <div class="gutter-box">
          <div class="box-top">
            <!-- <a-icon type="smile" style="font-size: 38px; color: rgb(127, 98, 173)" /> -->
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="briefcase"
              class="svg-inline--fa fa-briefcase fa-fw"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
              style="color: rgb(127, 98, 173)"
               width="58px"
              height="58px"
            >
              <path
                fill="currentColor"
                d="M184 48H328c4.4 0 8 3.6 8 8V96H176V56c0-4.4 3.6-8 8-8zm-56 8V96H64C28.7 96 0 124.7 0 160v96H192 320 512V160c0-35.3-28.7-64-64-64H384V56c0-30.9-25.1-56-56-56H184c-30.9 0-56 25.1-56 56zM512 288H320v32c0 17.7-14.3 32-32 32H224c-17.7 0-32-14.3-32-32V288H0V416c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V288z"
              ></path>
            </svg>
            <div class="box-top-right">
              <span class="number">{{ homeStatData.subjectsNum }}</span>
              <span class="title">{{ $t('首页.科目') }}</span>
            </div>
          </div>
          <a-divider style="margin:3px 0px"/>
          <div style="text-align: right;padding:10px 0px">
            <a-button class="btn btnSize" @click="gotoRouterClin">
              {{ $t('首页.临床医生') }}
              <a-icon type="right" style="width: 26px; height: 12px; color: #fff" />
            </a-button>
          </div>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
// import {timeFix} from '@/utils/util'
// import * as echarts from 'echarts'
// import {mapGetters} from 'vuex'
// import {PageHeaderWrapper} from '@/components/ProLayout'
// import {getUserProfile} from '@/api/system/user'
// import {goodsResult, homeResult,advertResult} from '@/api/statistics/home'
// import {orderAmountChart} from "@/api/statistics/order";
// import { RightOutLined } from '@ant-design/icons-vue'

import { getHomeStatData } from '@/api/home/home'

export default {
  name: 'Index',
  components: {
    // RightOutLined,
  },
  data() {
    return {
      homeStatData: {},
      // dataList: {
      //   today:{},
      //   yesterday:{},
      // }, //首页看板
      // advert: {}, //广告
      // goodsShare: {}, //商品
      // timeFix: timeFix(),
      // // 用户信息
      // user: {
      //   dept: {
      //     deptName: ''
      //   }
      // },
      // orderAmountParam: {
      //   chartType: 'days',
      //   dateRange: [],
      //   startTime: undefined,
      //   endTime:undefined,
      // },
      // orderCountParam: {
      //   chartType: 'days',
      //   dateRange: [],
      //   startTime: undefined,
      //   endTime:undefined,
      // },
      // /*销售数据选择*/
      // isOrderSelect: 'days',
      // isOrderCountSelect: 'days',
      // roleGroup: {},
      // postGroup: {},
    }
  },
  computed: {
    // ...mapGetters([
    //   'avatar',
    //   'nickname'
    // ])
  },
  created() {
    // this.getUser()
    // this.getHomeStatistics();
    // this.getAdvertResult();
    // this.getGoodResult();
    this.getHomeStatData()
  },
  mounted() {
    // this.drawOrderAmountChart()
    // this.drawOrderCountChart()
  },
  methods: {
    // 跳转到pcba
    gotoRouterPcba() {
      this.$router.push('/pcba')
    },
    // 跳转到
    gotoRouterClin() {
      this.$router.push('/studiesSensor')
    },
    //获取首页统计数据
    getHomeStatData() {
      getHomeStatData(this.queryParam).then((response) => {
        this.homeStatData = response.data
      })
    },
  },
}
</script>

<style lang="less" scoped>
@import './index.less';

.content {
  // background-color: red;
}

.gutter-box {
  background: #fff;
  padding: 8px 0;
  border-radius: 4px;

  .box-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    .icon {
      width: 80px;
      height: 80px;
      //  color: #000;
      line-height: 80px;
    }
    .box-top-right {
      display: flex;
      flex-direction: column;
      text-align: right;
      justify-content: space-between;
      .number {
        font-size: 3rem;
        font-weight: 700;
        color: #25476a;
      }
      .title {
        font-size: 1.6rem;
        font-weight: 700;
        color: #25476a;
        font-family: Poppins, sans-serif;
      }
    }
  }
  .btn {
    width: 96.5px;
    height: 38px;
    // align-items: center;
    background-color: #7f62ad;
    border-color: #7f62ad;
    box-shadow: none !important;
    // display: flex;
    // white-space: pre;
    color: #fff;
    font-weight: 500;
    line-height: 38px;
    border: 1px solid transparent;
    // padding: 0.375rem 0.75rem;
    font-size: 1rem;
    border-radius: 0.25rem;
    margin-right: 16px;
  }
  .btnSize {
    width: 123px;
  }
}
</style>
